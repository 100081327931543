<script lang="ts" setup>

import {computed, ref} from "vue";
import Multiselect from 'vue-multiselect'
import ReadonlyField from "@/Components/Fields/ReadonlyField.vue";

interface Option {
	value: number,
	name: string
}

interface SelectField {
	options: Array<Option>,
	value: any
}

const props = defineProps<{
	field: SelectField,
	readonly: {
		type: Boolean
		default: false
	}
}>()

const emit = defineEmits<{
	(e: 'itemRemoved', fieldName: string, id: number)
}>();

const readonlyValue = computed(() => {
	for (let option of props.field.options) {
		if (option.value == props.field.value) {
			return option.name;
		}
	}

	return '-'
})

const computedCurretValue = computed(() => {
	for (let option of props.field.options) {
		if (option.value == props.field?.value) {
			return option
		}
	}

	return null;
});
const currentValue = ref(computedCurretValue)

const computedOptions = computed(() => {
	if (props.field.options.length > 0) {
		return props.field.options;
	}

	return [
		{name: '', value: null}
	]
});

const valSelected = (selectedOption: Option, id) => {
	props.field.value = selectedOption.value;
}

const valRemoved = (selectedOption: Option, id) => {
	props.field.value = null;
	emit('itemRemoved', props.field.key, selectedOption.value);
}

</script>

<template>
	<ReadonlyField v-if="readonly" :field="field" :value="readonlyValue"></ReadonlyField>
	<!--	<Select v-else v-model="field.value" :options="field.options"/>-->
	<Multiselect
		v-else-if="computedOptions?.length > 0"
		v-model="currentValue"
		:close-on-select="true"
		:multiple="false"
		:options="field.options"
		:searchable="false"
		:show-labels="false"
		label="name"
		placeholder=""
		style=" border-radius: 50px !important;"
		track-by="value"
		@remove="valRemoved"
		@select="valSelected"
	/>
</template>