<script setup>
import {computed} from "vue";
import moment from "moment";

var props = defineProps({
	value: {
		default: ''
	},
	field: {
		type: Object,
		default: null
	}
})

const parsedValue = computed(()=>{
	let value = props.value

	if(props.field?.sub_type == 'datetime-local' || props.field?.type == 'datetime-local'){
		let date = new moment(value)
		if(date.isValid())
			return date.format('DD-MM-YYYY')
	}

	return value
})

</script>
<template>
<div class="block w-full h-full text-gray-600 sm:text-sm text-ellipsis overflow-hidden">
	{{ parsedValue }}
</div>
</template>

<style scoped>

</style>