<script setup lang="ts">

import {computed, ref} from 'vue'
import Multiselect from 'vue-multiselect'
import ReadonlyField from '@/Components/Fields/ReadonlyField.vue'

interface Option {
	id: number,
	title: string,
	parent_id: number | null
}

interface SelectField {
	options: Option[],
	value: any,
	key: string,
	parent: boolean
}

const props = defineProps<{
	field: SelectField,
	readonly: {
		type: Boolean
		default: false
	}
}>()

const emit = defineEmits<{
	(e: 'itemRemoved', fieldName: string, id: number)
}>();

const readonlyValue = computed(() => {
	const value = props.field.options.find(element => element.id == props.field.value)

	return value.title
})

const computedOptions = computed(() => {
	if (props.field.options.length) {
		return props.field.options.map(function (field: any) {
			const disabled = field.parent_id == null;
			return {
				id: field.id,
				name: field.parent_id != null ? '- ' + field.title : field.title,
				$isDisabled: props.field.parent ? disabled : false
			};
		});
	}

	return [{id: null, name: ''}]
});

const computedCurrentValue = computed(() => computedOptions.value?.find(o => o.id === parseInt(props.field?.value)));
const currentValue = ref(computedCurrentValue);

const valSelected = (selectedOption, id) => {
	props.field.value = selectedOption.id;
}

const valRemoved = (selectedOption, id) => {
	props.field.value = null;
	emit('itemRemoved', props.field.key, selectedOption.id);
}

</script>

<template>
	<ReadonlyField v-if="readonly" :field="field" :value="readonlyValue"></ReadonlyField>
	<Multiselect
		v-else-if="computedOptions?.length > 0"
		style="border-radius: 50px !important;"
		v-model="currentValue"
		label="name"
		track-by="id"
		:options="computedOptions"
		placeholder=""
		:searchable="false"
		:show-labels="false"
		@select="valSelected"
		@remove="valRemoved"
	/>
</template>