<script setup lang="ts">
import {computed, ref} from 'vue'
import Multiselect from 'vue-multiselect'
import ReadonlyField from "@/Components/Fields/ReadonlyField.vue";
import 'vue-multiselect/dist/vue-multiselect.css'
import {translate} from "../../../ts/helpers/translationHelper";

interface TagsField {
	value: Array<string>
	tags: Object
}

const props = defineProps<{

	field: TagsField,
	readonly: {
		type: Boolean
		default: false
	}
}>()

const emit = defineEmits<{
	(e: 'tagRemoved', tagName: string)
}>();

const dirty = ref(false);
const options = ref(props.field.tags);

function addTag(newTag) {
	options.value.push(newTag);

	// catch value is null error
	if (props.field.value == null)
		props.field.value = []

	props.field.value.push(newTag);
}

const removeTag = (selectedOption, id) => {
	dirty.value = true; // do not know where this is used....
	emit('tagRemoved', selectedOption);
};

const readonlyValue = computed(() => {
	let value = props.field.value
	if (value instanceof Array<string>) {
		return value.join(', ')
	}
	return value
})

</script>

<template>

	<ReadonlyField v-if="readonly" :value="readonlyValue"></ReadonlyField>
	<Multiselect
		style=" border-radius: 25px !important;"
		v-else
		v-model="field.value"
		:options="options"
		:tag-placeholder="translate('fields.tagsAddText')"
		:placeholder="translate('fields.tagsSearchText')"
		selected-label=""
		:close-on-select="false"
		select-label=""
		deselect-label=""
		:multiple="true"
		:taggable="true"
		:show-labels="false"
		@tag="addTag"
		@remove="removeTag"
	>
		<template v-slot:noOptions="">
			{{ translate('fields.noTags') }}
		</template>
	</Multiselect>
</template>