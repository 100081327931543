<script setup lang="ts">

import SelectPerson from '@/Components/SelectPerson.vue'
import ClaimStatusIcon from "@/Components/Persons/ClaimStatusIcon.vue";
import {XMarkIcon as TrashIcon} from '@heroicons/vue/20/solid'
import {useColors} from '@/Services/colors.js'
import axios from "axios";
import {Inertia} from "@inertiajs/inertia";
import {translate} from "../../../ts/helpers/translationHelper";
import {ref} from "vue";

interface FacesField {
	value: string,
	faces: Array<Face>,
	people: Array<Person>
}

interface Person {
	id: number;
	name: string;
}

interface Face {
	id: number;
	image: string;
	person: Person | null;
}

const props = defineProps<{
	field: FacesField,
	belongsToAnImage: boolean,
	readonly: {
		type: Boolean
		default: false
	}
}>();

const emits = defineEmits<{
	(e: 'triggerAddFace'): void
}>();

function update(n) {
	if (n == '') {
		return;
	}

	if (typeof (n) == 'object')
		n = n.name;
}

async function deleteFace(index) {
	let face = props.field.faces[index]
	await axios.delete('/api/faces/' + face.id)
	props.field.faces.splice(index, 1)
}

const colors = useColors()

async function AddFace() {
	disableAddFace.value = true;
	setTimeout(() => disableAddFace.value = false, 2000);
	if (props.belongsToAnImage) {
		emits('triggerAddFace');
		return;
	}

	try {
		let data = {
			x: 0,
			y: 0,
		}

		await axios.post(`/api/images/${props.field.imageId}/faces`, data)

		Inertia.reload()
	} catch (e) {
		console.error(e)
	}
}

const disableAddFace = ref(false);

</script>

<template>
	<li v-for="(face, index) in field.faces" class="flex py-1">
		<div v-if="(readonly && face?.person != null) || !readonly">
			<div v-if="readonly">
				<div class="flex">
				<span class="relative inline-block">
					<img class="h-14 w-14 rounded-full" :src="face.person?.avatar" alt="Avatar"/>
					<ClaimStatusIcon v-if="face.person" :status="face.person?.claim?.status"/>
				</span>
					<div class="pt-4 flex-1 pl-4">
						{{ face.person?.name }}
					</div>
				</div>
			</div>
			<div class="flex gap-2" v-else>
				<div class="flex-col justify-center">
					<div class="relative h-14 w-14 ">
						<img class="rounded-full" :src="face.image" alt="face"/>
						<ClaimStatusIcon
							v-if="face.person"
							:status="face.person?.claim?.status"
						/>
					</div>
				</div>
				<div class="pt-2 flex-grow w-full">
					<SelectPerson
						width="w-[313px]"
						v-model="field.value[face.id]"
						:show-check-button="false"
						:people="field.people"
					/>
				</div>
				<div class="pt-3.5 text-center">
					<div @click="deleteFace(index)"
					     :style="['color:' + colors.textColor, 'background-color:' + colors.primaryColor]"
					     class="inline-flex items-center rounded-full border border-transparent p-1 text-white shadow-sm cursor-pointer">
						<TrashIcon class="h-5 w-5"/>
					</div>
				</div>
			</div>
		</div>
	</li>
	<div v-if="!readonly" class="flex">
		<PrimaryButton :disabled="disableAddFace" @click="AddFace"
		               class="my-4 flex">
			{{ translate('cropModal.manualAdd') }}
		</PrimaryButton>
	</div>
</template>
