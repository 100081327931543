<script lang="ts" setup>

import Multiselect from 'vue-multiselect'
import {computed, ref} from "vue";
import ReadonlyField from "@/Components/Fields/ReadonlyField.vue";
import 'vue-multiselect/dist/vue-multiselect.css'

interface Option {
	value: number,
	name: string
}

interface MultiSelectField {
	options: Array<Option>,
	value: any,
	key: string
}

const props = defineProps<{
	field: MultiSelectField,
	readonly: {
		type: Boolean
		default: false
	}
}>();

const emit = defineEmits<{
	(e: 'itemRemoved', fieldName: string, id: number)
}>();

const readonlyValue = computed(() => {
	const value = props.field.value

	if (value instanceof Array) {
		var arrayVal = [];

		for (let val of value) {
			for (let option of props.field.options) {
				if (option.value == val) {
					arrayVal.push(option.name)
				}
			}
		}

		return arrayVal.join(', ')
	}

	for (let option of props.field.options) {
		if (option.value == value) {
			return option.name;
		}
	}

	return '-'
});

const computedCurretValue = computed(() => {
	let currentOptions = [];

	if (props.field.value != null) {
		for (let option of props.field.options) {
			if (props.field.value.includes(option.value)) {
				currentOptions.push(option);
			}
		}
	}

	return currentOptions;
});
const currentValue = ref(computedCurretValue)

const valSelected = (selectedOption, id) => {
	if (!props.field.value)
		props.field.value = [];

	props.field.value.push(selectedOption.value);
}

const valRemoved = (selectedOption, id) => {
	let index = props.field.value.findIndex(v => v == selectedOption.value);

	if (index > -1)
		props.field.value.splice(index, 1);

	emit('itemRemoved', props.field.key, selectedOption.value);
}

</script>

<template>
	<ReadonlyField v-if="readonly" :value="readonlyValue"></ReadonlyField>
	<Multiselect
		v-else-if="field.options.length > 0"
		v-model="currentValue"
		:clear-on-select="false"
		:close-on-select="false"
		:multiple="true"
		:options="field.options"
		:searchable="false"
		:show-labels="false"
		label="name"
		placeholder=""
		style="border-radius: 50px !important;"
		track-by="value"
		@remove="valRemoved"
		@select="valSelected"
	/>
</template>
<style scoped>
.multiselect {
	border-radius: 50px !important;
}
</style>