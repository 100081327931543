import './bootstrap';
import '../css/app.css';

import {createApp, h} from 'vue';
import {createInertiaApp} from '@inertiajs/inertia-vue3';
import {InertiaProgress} from '@inertiajs/progress';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {translate} from './VueTranslation/translation';
import Vue3ColorPicker from "vue3-colorpicker";
import "vue3-colorpicker/style.css";
import {createPinia} from 'pinia'
import Layout from '@/Layouts/AuthenticatedLayout.vue';
import PrimaryButton from "@/Components/PrimaryButton.vue";
import SecondaryButton from "@/Components/SecondaryButton.vue";
import * as Sentry from '@sentry/vue'

import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.es.js';


const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

import TextField from '@/Components/Fields/TextField.vue'
import CategoryField from '@/Components/Fields/CategoryField.vue'
import TextAreaField from '@/Components/Fields/TextAreaField.vue'
import SelectField from '@/Components/Fields/SelectField.vue'
import MultiSelectField from '@/Components/Fields/MultiSelectField.vue'
import TagsField from '@/Components/Fields/TagsField.vue'
import FacesField from '@/Components/Fields/FacesField.vue'
import ReadField from '@/Components/Fields/ReadField.vue'
import { AVPlugin } from 'vue-audio-visual'

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({el, app, props, plugin}) {
        let pinia = createPinia();

        const vueApp = createApp({render: () => h(app, props)})
            .use(plugin)
            .use(ZiggyVue, Ziggy)
            .use(Vue3ColorPicker)
            .use(pinia)
            .use(AVPlugin)
            .component('Layout', Layout)
            .component('PrimaryButton', PrimaryButton)
            .component('SecondaryButton', SecondaryButton)
            .component('TextField', TextField)
            .component('SelectField', SelectField)
            .component('ReadField', ReadField)
            .component('MultiSelectField', MultiSelectField)
            .component('TagsField', TagsField)
            .component('CategoryField', CategoryField)
            .component('FacesField', FacesField)
            .component('TextAreaField', TextAreaField)

        Sentry.init({
            app: vueApp,
            dsn: import.meta.env.VITE_SENTRY_LARAVEL_DSN,
            integrations: [Sentry.browserTracingIntegration()],
            tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,
        })

        vueApp.mount(el)

        return vueApp
    },
});

window.translate = translate;

InertiaProgress.init({color: '#4B5563'});
